import React ,{ useState, useRef ,useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate ,Link , useParams} from "react-router-dom";
import { ReactComponent as ClockIcon } from "../../assets/Icons/clock.svg";
import { ReactComponent as Speaker } from "../../assets/Icons/speaker.svg";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/Icons/instagram.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as ClipBoard } from "../../assets/Icons/clipBoard.svg";
import { ReactComponent as Closeicon } from "../../assets/Icons/cross.svg";
import { ReactComponent as ShareIcon } from "../../assets/Icons/share.svg";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";

import { getUser } from '../../Redux/UserSlice/UserSlice';
import { getUserSubscriptionData } from '../../Redux/UserSubscriptionDetails/userSubscriptionSlice';
import { getEventInfo,convertDateAndTimeToLocal, imageUrlCheck, rowItemCount} from '../../utils/utils';
import { CopyToClipboard } from "react-copy-to-clipboard";
import LogoutAllModal from "../../Components/Modals/LogoutAllModal";
import useScrollPosition from "../../Components/ScrollPosition/useScrollPosition";
import { useWindowWidth } from "@react-hook/window-size";
import { authenticate2, getUserSubscription } from '../../network/service';
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import Loading from '../../Components/Loading/Loading';
import { getVideoSubscription } from "../Subscription/service";
import { toast } from 'react-toastify';
const EventDetailsScreen= () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const [eventDetails,setEventDetails] = useState(false)
  const [logoutModal,setLogoutModal] = useState(false)
  const [isReport,setIsReport] = useState(false)
  const [isShareActive,setIsShareActive] = useState(false)
  const [subscribeButton,setSubscribeButton] = useState(false)
  const [loading, setLoading] = useState(true);
  const [initialVideoSubscriptions, setInitialVideoSubscriptions] = useState([]);
  const [modalType, setModalType] = useState({ type: "logoutAll" });
  const [pageURL, setPageURL] = useState("");
  const [subscribedUser, setSubscribeduser] = useState(false);
  const [isSubscribedUser, setIsSubscribedUser] = useState();


  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {eventId} = useParams()
  let shareRef = useRef(null);
  let reportRef = useRef(null);
  let shareIconRef = useRef(null);
  let shareTwoRef = useRef(null);
  let reportTwoRef = useRef(null);
  let shareIconTwoRef = useRef(null);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    window.scroll(0,0)
    setPageURL(window.location.href);

  }, []);

  useEffect(() => {
    if (eventId) {
      if (eventId !== "null" && eventId !== "undefined") {
        const fetchApiCalls = async () => {
            await fetchUserSubscriptionDetails(); 
            await fetchEventInfo()

        }
        fetchApiCalls()
      }
    }
  }, [eventId]);


  useEffect(()=>{
    if(eventDetails){
      if(Object.entries(eventDetails).length > 0){
          if(projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true"){
            if(projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true"){
              let isSubscribedUser;
              if(eventDetails?.subscriptions?.length>0){
                isSubscribedUser = userSubscriptionData?.data?.length > 0
                  ? subscriptionCheck(userSubscriptionData?.data, eventDetails?.subscriptions)
                  : false;
                  if(isSubscribedUser === true){
                    setSubscribeButton(false)
                    setIsSubscribedUser(true)
                  }else{
                    setIsSubscribedUser(false)
                    setInitialVideoSubscriptions(eventDetails?.subscriptions)
                    if(eventDetails?.free_event === true){
                    setSubscribeButton(false)
                    }else{
                      setSubscribeButton(true)
                    }
                  }
              }else{
                setSubscribeButton(false)
                setIsSubscribedUser(true)
              }
            }else{
              setSubscribeButton(false)
              setIsSubscribedUser(true)
            }
          }else{
            if(userSubscriptionData?.login_needed === false){
              setSubscribeButton(false)
              setIsSubscribedUser(true)
            }
          }
      }
    }
  },[eventDetails])

  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };

  const redirectToLogin = () => {
    dispatch(
      getUser({
        user: null,
      })
    );
    localStorage.removeItem("userId");
    tokenAuthenticate();
    navigate("/login", {
      state: { path: location?.pathname, showId: eventId },
    });
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate2(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
    }
  };

  const handleSubscribe = (data) => {
    if (user) {
      localStorage.setItem("selectedSubId", data?.subscription_id);
      localStorage.setItem("selectedAmount", data?.price);
      localStorage.setItem("eventId", eventDetails?.event_id);
      localStorage.setItem("vanityUrl", eventId);
      localStorage.setItem("eventType", eventDetails?.type);
      navigate("/payment", { state: { subscription: { ...data, is_coupon: false } } });
    } else {
      navigate("/login", {
        state: { path: "/event/" + eventId, showId: eventId },
      });
    }
  };

  const fetchVideoSubscription = async (eventDetails) => {
    try {
      const type = "event";
      const videoSubscriptionRespone = await getVideoSubscription(appInfo, eventDetails?.event_id, type);
      if (videoSubscriptionRespone?.status === 200) {
        return videoSubscriptionRespone?.data?.data;
      }
    } catch (err) { }
  };
  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub.some((user) => videoSub.some((video) => user.sub_id === video.subscription_id));
    return hasMatch;
  };
  const watchVideoHandler = async () => {
    if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
      subscriptionCheckAndVideoPlay();
    } else {
      if (userSubscriptionData?.login_needed === false) {
        playVideo();
      } else if (userSubscriptionData?.login_needed === true) {
        setModalType({ type: "videoWatchLimitExpired" });
        setLogoutModal(true);
      } else {
        subscriptionCheckAndVideoPlay();
      }
    }
  };
  const subscriptionCheckAndVideoPlay = async () => {
    if (user) {
      if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
        if (userSubscriptionData?.forcibleLogout === true) {
          setLogoutModal(true);
          setModalType({
            type: "logoutAll",
            heading: "You’ve reached the maximum Device limit.Do you want to logout from all devices",
          });
        } else if (userSubscriptionData?.session_expired === true) {
          setModalType({ type: "redirect" });
          setLogoutModal(true);
          setTimeout(() => {
            redirectToLogin();
          }, 1000);
        } else {
          if (eventDetails?.subscriptions?.length > 0) {
            const res = await  fetchVideoSubscription(eventDetails)
            let isSubscribedUser;
              isSubscribedUser =
                userSubscriptionData?.data?.length > 0
                  ? subscriptionCheck(userSubscriptionData?.data, res)
                  : false;
           
            if (isSubscribedUser === true) {
              playVideo();
            } else {
              if (eventDetails?.free_event === true) {
                playVideo();
              } else {
                localStorage.setItem("eventId", eventDetails?.event_id);
                localStorage.setItem("vanityUrl", eventId);
                localStorage.setItem("eventType", eventDetails?.type);

                navigate("/subscription", {
                  state: { eventId: eventDetails?.event_id },
                });
              }
            }
          } else {
            if (eventDetails) {
              playVideo();
            }
          }
        }
      } else {
        playVideo();
      }
    } else {
      navigate("/login", {
        state: { path: "/event/" + eventId, showId: eventId },
      });
    }
  };
  const playVideo = async () => {
    if (eventDetails?.type !== "UPCOMING") {
      navigate("/player",
        { state: { eventId: eventDetails?.event_id } }
      );
    } else {
      toast.error("Event is not started yet")
    }
  };

  const fetchEventInfo = async () => {
    try{
      const response = await getEventInfo(appInfo,eventId);
      setEventDetails(response?.data?.data)
      setLoading(false)
    }catch(err){
      setLoading(false)
    }
  }
  if(loading){
    return(<Loading/>)
  }
 
    return (
      <div class="eventDetailsScreen">
        {logoutModal && (
          <LogoutAllModal
            showId={eventId}
            appInfo={appInfo}
            setLogoutModal={setLogoutModal}
            modalType={modalType}
            // videoDetails={selectedVideoDetails} 
          />
        )}
        <div
          class="topBackgroundContainer"
          style={{
            backgroundImage: `url(${
              imageUrlCheck(eventDetails?.thumbnail_350_200) === true
                ? eventDetails?.thumbnail_350_200
                : process.env.REACT_APP_IMAGE_URL + eventDetails?.thumbnail_350_200
            })`,
          }}
        >
          <div className="contents">
            <div class="left">
              <h1 class="title">{eventDetails?.event_name}</h1>
              <div class="showMoreInfo">
                {eventDetails?.schedule_time && (
                  <div class="duration">
                    <div class="icon">
                      <ClockIcon />
                    </div>
                    <span>{convertDateAndTimeToLocal(eventDetails?.schedule_time)}</span>
                  </div>
                )}
                 {eventDetails?.categories[0]?.category_name && (
                  <div  class="categoryname" >
                     <h2 class="category" > Categories     
                      <span>{eventDetails?.categories[0]?.category_name}</span> 
                    </h2>
                    </div>
                   
                  )}
              </div>  
            </div>
  
            <div class="right"> 
              {eventDetails?.description && (
                <div class="description">
                  <p class="data">{eventDetails?.description}</p>
                </div>
              )}
            </div>
          </div>
          <div className="bottomGradient"></div>
          <div className="shareContainer">
            <span className={isShareActive ? "active" : ""} ref={shareRef} onClick={() =>setIsShareActive(true)}>
              Share
            </span>
            {isShareActive && (
              <div className="socialIcons">
                <FacebookShareButton url={pageURL} quote={"Share"} className="facebook">
                  <div className="icon">
                    <FacebookIcon />
                  </div>
                </FacebookShareButton>
  
                <TwitterShareButton url={pageURL} className="twitter">
                  <div className="icon">
                    <TwitterIcon />
                  </div>
                </TwitterShareButton>
                <CopyToClipboard className="clipBoard" text={pageURL} title="Copy link">
                  <div className="icon" onClick={() => alert("Link copied")}>
                    <ClipBoard />
                  </div>
                </CopyToClipboard>
              </div>
            )}
            <div className="icon" ref={shareRef}>
              {isShareActive ? (
                <Closeicon className="closeIcon" onClick={() => setIsShareActive(false)} />
              ) : (
                <ShareIcon onClick={() => setIsShareActive(true)} />
              )}
            </div>
          </div>
          {/* <div className="playIconContainer" >
          <PlayIcon />
          </div> */}
        {!isSubscribedUser || eventDetails?.free_event === true ? (
                eventDetails?.subscriptions?.length > 0 || eventDetails?.free_event === true ? (
                  <div className="subscriptions">
                    {initialVideoSubscriptions?.map((item, index) => (
                      <div className="items" key={index} onClick={() => handleSubscribe(item)}>
                        {item?.subscription_text}
                      </div>
                    ))}
                    {eventDetails?.free_event === true && (
                      <div className="items free" onClick={() => watchVideoHandler()}>
                        Free
                      </div>
                    )}
                  </div>
                ) : null
              ) : null}
         
        </div>
        <div className="responsiveContent">
             {eventDetails?.description && (
                <div class="description">
                  <p class="data">{eventDetails?.description}</p>
                </div>
              )}
        </div>
      </div>
    );
  };
export default EventDetailsScreen;